.main-header-dropdown.dropdown-menu.show {
  display: block;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(-12px, 60.8px, 0px);
}

a {
  cursor: pointer;
}

.round-cross-120 {
  width: 120px;
  height: 120px;
  line-height: 140px;
  text-align: center;
  border-radius: 50%;
  background: #fa0602;
}

[data-theme-mode="dark"] .table > :not(caption) > * > * {
  background-color: var(--body-bg-rgb) !important;
  color: var(--default-text-color) !important;
}

.w-310 {
  width: 310px;
}

@media (max-width: 576.98px) {
  .w-310 {
    width: 100%;
  }
}

.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 200px;
}

@media (min-width: 1400px) {
  .ml-220 {
    margin-left: 290px;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .ml-220 {
    margin-left: 80px;
  }
}
